<script setup lang="ts">
defineLayout({
  label: 'Wyndham Layout',
})

const config = {
  stacked: false,
  logoPosition: 'left', // left or center
  menuPosition: 'center', // right or center
  backgroundClass: 'bg-transparent',
  containerClasses: 'border-b border-white',
  bookNowBackground: 'bg-[#fb9c9c]',
  languageSelectorText: 'text-white',
  bookNowText: 'text-white',
  booking: false,
  noSpacer: true,
  scrollTriggerDistance: 100,
  scrollBooking: true,
  scrollClass: 'bg-white',
  textClass: 'text-white',
  backdropBlur: true,
}

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, setupHeader } = usePlayaLayout()

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/wyndham/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/wyndham/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/wyndham/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/wyndham/favicons/manifest.json', type: 'application/json' },
]

if (!isVoixTemplatizer)
  setupHeader(null, links)

const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, [])

provide('accentColor', { value: '#fb9c9c' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)
</script>

<template>
  <WyndhamLayoutWrapper :config="config">
    <slot />
  </WyndhamLayoutWrapper>
</template>

<style lang="scss">
  @import '~/assets/css/wyndham.scss';
</style>
